<template>
  <v-card
    v-if="greenPassData"
    class="pa-2"
  >
    <GoBack />
    <v-card-title>Všeobecné údaje</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Overený digitálny podpis</v-list-item-title>
            <v-list-item-content style="display:block;">
              <v-icon
                v-if="!isValidCertificate"
                color="red"
              >
                mdi-close
              </v-icon>
              <v-icon
                v-else
                color="green"
              >
                mdi-check
              </v-icon>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <template v-if="greenPassData.nominative">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Meno</v-list-item-title>
              <v-list-item-content>{{ greenPassData.nominative.gn }}</v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Priezvisko</v-list-item-title>
              <v-list-item-content>{{ greenPassData.nominative.fn }}</v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Krajina</v-list-item-title>
            <v-list-item-content>{{ greenPassData.nation }}</v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Dátum narodenia</v-list-item-title>
            <v-list-item-content>{{ greenPassData.dateOfBirth }}</v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="my-5" />
      <v-expansion-panels
        id="green-pass-results-expansion-panels"
        style="width: 100%"
        flat
        multiple
      >
        <v-expansion-panel v-if="greenPassData.vaccines">
          <v-expansion-panel-header>
            Vakcinovaný
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <results-vaccine :vaccine="greenPassData.vaccines" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="greenPassData.tests">
          <v-expansion-panel-header>
            Testovaný
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <results-test :test="greenPassData.tests" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="greenPassData.recover">
          <v-expansion-panel-header>
            Zotavený po COVID-e
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <results-recover :recover="greenPassData.recover" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="certificate">
          <v-expansion-panel-header>
            Vydavateľ certifikátu
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <results-signer-data :certificate="certificate" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { GreenPassCertificate } from '../helpers/green-pass'
import ResultsVaccine from '../components/Results/ResultsVaccine'
import ResultsTest from '../components/Results/ResultsTest'
import ResultsRecover from '../components/Results/ResultsRecover'
import ResultsSignerData from '../components/Results/ResultsSignerData'
import GoBack from '../components/GoBack'

export default {
  name: 'Results',
  components: { ResultsSignerData, ResultsVaccine, ResultsTest, ResultsRecover, GoBack },
  props: {
    qr: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isValidCertificate: null,
    certificate: null,
    greenPass: null,
    greenPassData: {}
  }),
  mounted () {
    this.greenPass = new GreenPassCertificate(this.qr)
    this.greenPassData = this.greenPass.toObject()
    this.greenPass.verify().then((val) => {
      this.isValidCertificate = val.verified
      this.certificate = val.certificate
    }).catch(e => {
      alert(e.message)
    })
  }
}
</script>

<style scoped>
.green-pass-table tr > v-list-item-title:first-child {
  font-weight: bold;
}

.green-pass-table tr > v-list-item-title:nth-child(2) {
  text-align: right;
}
</style>
<style>
#green-pass-results-expansion-panels .v-expansion-panel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0.5em 0;
}

#green-pass-results-expansion-panels .v-expansion-panel:last-child {
  border-bottom: 0;
}

#green-pass-results-expansion-panels .v-expansion-panel-header {
  padding-left: 8px;
  padding-right: 8px;
}

#green-pass-results-expansion-panels .v-expansion-panel-content,
#green-pass-results-expansion-panels .v-expansion-panel-content > .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
